/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useState } from 'react';

import { IMultipleSelectOptionKeyValue, IMultipleSelectValue } from '@lux-ds/data-grid';
import { LuxTableRowImage, MaterialIcons } from '@luxclusif/material';
import Tooltip from '@mui/material/Tooltip';
import {
  GridCellParams,
  GridColDef,
  GridSelectionModel,
  GridValueFormatterParams,
  GridValueGetterParams
} from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';

import ImportItemsModal from 'components/PurchaseOrders/ImportItemsModal/ImportItemsModal';
import StatusIndicator from 'components/StatusIndicator/StatusIndicator';
import LuxDataTables from 'components/TableNew/LuxDataTable';
import {
  EColumnFilterTypes,
  IHeaderButton,
  TColumnFilters,
  TColumnFiltersValues,
  TOnChangeColumnFilter
} from 'models/component/luxDataTableInterface';
import {
  EOrderItemsFilterId,
  EOrderItemStatus,
  EPurchaseOrderAllStatus,
  IOrderItemsColFilters,
  IOrderItemStatus,
  ISelectedEntity,
  ITrackingNumber,
  TGetAllCouriers,
  TGetAllCurrencies
} from 'models/purchaseOrders';
import { IItemErrorWarning, IItemInfo, IPurchaseInfo } from 'pages/PurchaseOrder/schema';
import purchaseOrdersService from 'services/PurchaseOrders.service';

import ActionsModal, { EOrderItemsAction } from './components/ActionsModal/ActionsModal';
import TrackingNumberModal from './components/TrackingNumberModal/TrackingNumberModal';

import orderItemsStyles, { TextStyle } from './orderItems.styles';

const colDefaults: Partial<GridColDef> = {
  sortable: false
};

interface IProps {
  couriers: TGetAllCouriers;
  currencies: TGetAllCurrencies;
  isLoadingCouriers: boolean;
  status?: EPurchaseOrderAllStatus;
}

const OrderItems: React.FC<IProps> = ({ couriers, currencies, isLoadingCouriers, status }) => {
  const { t } = useTranslation(['common', 'purchaseOrders']);
  const classes = orderItemsStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { control, setValue, watch } = useFormContext<IPurchaseInfo>();
  const orderItems = watch('items', []);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [filteredItems, setfilteredItems] = useState<IItemInfo[]>(orderItems);
  const [activeFilters, setActiveFilters] = useState<IOrderItemsColFilters>({
    [EOrderItemsFilterId.Brand]: [],
    [EOrderItemsFilterId.Category]: [],
    [EOrderItemsFilterId.Status]: [],
    [EOrderItemsFilterId.Templated]: [],
    [EOrderItemsFilterId.TrackingNumber]: []
  });
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);
  const [itemsAction, setItemsAction] = useState<EOrderItemsAction | undefined>(undefined);
  const [openTrackingNumberModal, setOpenTrackingNumberModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(25);

  const { poId = '' } = useParams<{ poId: string }>();

  const currencyId = watch('currencyId');
  const poNumber = watch('poNumber');
  const supplierId = watch('supplierId');
  const businessModelId = watch('businessModelId');

  const { exchangeRate = 0, symbol = '' } = currencies.find(({ currencyId: id }) => currencyId === id) || {};

  const initialOrderItems = orderItems.filter(({ itemId }) => !!itemId);

  const getLabel = useCallback(
    (value: IMultipleSelectValue) => ('code' in value ? value.code : (value as IMultipleSelectOptionKeyValue).value),
    []
  );

  const handleTrackingNumbers = (params: GridValueFormatterParams) => {
    const values = params.value as IMultipleSelectValue[];
    const [firstValue, ...otherValues] = values;

    let label = '';
    const tooltip = values.map((value: IMultipleSelectValue) => getLabel(value)).join('; ');

    if (values.length > 0) {
      label = `${getLabel(firstValue)}${otherValues.length > 0 ? ` +${otherValues.length}` : ''}`;
    }

    return { label, tooltip };
  };

  const handleTooltipTextStyle = useCallback((params: GridValueGetterParams) => {
    const tooltip = params.value && params.value?.toString()[0].toUpperCase() + params.value?.toString().slice(1);

    return { tooltip };
  }, []);

  const commonPopperProps = {
    // Note: Added this to fix the space between text and tooltip
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -42]
        }
      }
    ]
  };

  const columns: GridColDef[] = [
    {
      ...colDefaults,
      align: 'center',
      field: '__check__',
      width: 42
    },
    {
      ...colDefaults,
      cellClassName: classes.photoCell, // This class is needed while we use the 'LuxTableRowImage' component.
      field: 'photos',
      headerName: ' ',
      renderCell: Object.assign(
        ({ value }: GridCellParams) => <LuxTableRowImage imageSrc={(value as string[])[0] || ''} />,
        {
          displayName: 'Photo'
        }
      ),
      width: 84
    },
    {
      cellClassName: classes.skuHyperlink,
      field: 'sku',
      headerName: t('common:sku'),
      renderCell: params => (
        <a href={`${process.env.REACT_APP_INVENTORY_URL}/items/${params.id}`} target="_blank" rel="noreferrer">
          {params.value}
        </a>
      ),
      width: 170
    },
    {
      field: 'sellerCode',
      flex: 1,
      headerName: t('common:sellerCode'),
      renderCell: (params: GridValueGetterParams) => {
        return (
          <Tooltip title={params.value as ISelectedEntity} PopperProps={commonPopperProps}>
            <TextStyle>{params.value}</TextStyle>
          </Tooltip>
        );
      }
    },
    {
      cellClassName: classes.capitalizeCell,
      field: 'brand',
      flex: 1,
      headerName: t('common:brand'),
      renderCell: (params: GridValueGetterParams) => {
        const { tooltip } = handleTooltipTextStyle(params);

        return (
          <Tooltip title={tooltip as string} PopperProps={commonPopperProps}>
            <TextStyle>{params.value}</TextStyle>
          </Tooltip>
        );
      },
      valueGetter: (valueGetter: GridValueGetterParams) => (valueGetter.value as ISelectedEntity).name.toLowerCase()
    },
    {
      cellClassName: classes.capitalizeCell,
      field: 'category',
      flex: 1,
      headerName: t('purchaseOrders:poItems.subCategory'),
      renderCell: (params: GridValueGetterParams) => {
        const { tooltip } = handleTooltipTextStyle(params);

        return (
          <Tooltip title={tooltip as string} PopperProps={commonPopperProps}>
            <TextStyle>{params.value}</TextStyle>
          </Tooltip>
        );
      },
      valueGetter: (valueGetter: GridValueGetterParams) => (valueGetter.value as ISelectedEntity).name.toLowerCase()
    },
    {
      field: 'name',
      flex: 1,
      headerName: t('common:name'),
      renderCell: (params: GridValueGetterParams) => {
        return (
          <Tooltip title={params.value as ISelectedEntity} PopperProps={commonPopperProps}>
            <TextStyle>{params.value}</TextStyle>
          </Tooltip>
        );
      }
    },
    {
      ...colDefaults,
      field: 'costWithoutTax',
      flex: 1,
      headerName: t('purchaseOrders:poItems.costWithoutTax'),
      valueFormatter: (valueFormatter: GridValueFormatterParams) => `${symbol} ${valueFormatter.value}`
    },
    {
      ...colDefaults,
      field: 'costWithTax',
      flex: 1,
      headerName: t('purchaseOrders:poItems.costWithTax'),
      valueFormatter: (valueFormatter: GridValueFormatterParams) => `${symbol} ${valueFormatter.value}`
    },
    {
      ...colDefaults,
      field: 'usdCostPrice',
      flex: 1,
      headerName: t('purchaseOrders:poItems.costUSD'),
      valueFormatter: (valueFormatter: GridValueFormatterParams) =>
        valueFormatter.value ? `$ ${valueFormatter.value}` : '-',
      valueGetter: (valueGetter: GridValueGetterParams) => {
        if (typeof valueGetter.value === 'number') {
          return valueGetter.value.toFixed(2);
        }

        return exchangeRate !== 0
          ? ((valueGetter.getValue(valueGetter.id, 'costWithTax') as number) / exchangeRate).toFixed(2)
          : null;
      }
    },
    {
      ...colDefaults,
      field: 'status',
      flex: 1,
      headerName: t('common:status'),
      renderCell: Object.assign(
        ({ value }: GridCellParams) => {
          const { id, name } = value as IOrderItemStatus;

          return (
            <Tooltip title={name} PopperProps={commonPopperProps}>
              <TextStyle>
                <StatusIndicator label={name} statusId={id} />
              </TextStyle>
            </Tooltip>
          );
        },
        { displayName: 'Status' }
      )
    },
    {
      ...colDefaults,
      field: 'isFinalized',
      flex: 1,
      headerName: t('purchaseOrders:poItems.templated'),
      valueFormatter: (valueFormatter: GridValueFormatterParams) =>
        valueFormatter.value ? t('common:yes') : t('common:no')
    },
    {
      field: 'trackingNumbers',
      flex: 1,
      headerName: t('common:trackingNumber'),
      renderCell: (params: GridValueFormatterParams) => {
        const { label, tooltip } = handleTrackingNumbers(params);

        return label ? (
          <Tooltip title={tooltip} PopperProps={commonPopperProps}>
            <TextStyle>{label}</TextStyle>
          </Tooltip>
        ) : (
          '-'
        );
      }
    }
  ];

  const onChangeFilter: TOnChangeColumnFilter = (id, value) => {
    setActiveFilters(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const getFilterOptions = (key: keyof IItemInfo) =>
    orderItems
      .filter(
        (orderItem, index) =>
          orderItems.findIndex(item => (item[key] as ISelectedEntity).id === (orderItem[key] as ISelectedEntity).id) ===
          index
      )
      .map(orderItem => ({
        label: (orderItem[key] as ISelectedEntity).name,
        value: (orderItem[key] as ISelectedEntity).id
      }))
      .sort(({ value: valueA }, { value: valueB }) => (valueA > valueB ? 1 : -1));

  const filters: TColumnFilters = [
    {
      id: EOrderItemsFilterId.Brand,
      label: t('common:brand'),
      onChange: onChangeFilter,
      options: getFilterOptions('brand'),
      type: EColumnFilterTypes.MultipleSelect
    },
    {
      id: EOrderItemsFilterId.Category,
      label: t('purchaseOrders:poItems.subCategory'),
      onChange: onChangeFilter,
      options: getFilterOptions('category'),
      type: EColumnFilterTypes.MultipleSelect
    },
    {
      id: EOrderItemsFilterId.Status,
      label: t('common:status'),
      onChange: onChangeFilter,
      options: getFilterOptions('status'),
      type: EColumnFilterTypes.MultipleSelect
    },
    {
      id: EOrderItemsFilterId.Templated,
      label: t('purchaseOrders:poItems.templated'),
      onChange: onChangeFilter,
      options: [
        {
          label: t('common:yes'),
          value: 'true'
        },
        {
          label: t('common:no'),
          value: 'false'
        }
      ],
      type: EColumnFilterTypes.MultipleSelect
    },
    {
      id: EOrderItemsFilterId.TrackingNumber,
      label: t('common:trackingNumber'),
      onChange: onChangeFilter,
      options: [
        {
          label: t('purchaseOrders:poItems.assigned'),
          value: 'true'
        },
        {
          label: t('purchaseOrders:poItems.notAssigned'),
          value: 'false'
        }
      ],
      type: EColumnFilterTypes.MultipleSelect
    }
  ];

  const handleSelectedItems = (selectedIds: GridSelectionModel) => {
    const oldSelectedIds = selectedItems.filter(id => !filteredItems.some(({ itemId }) => itemId === id));

    setSelectedItems([...oldSelectedIds, ...(selectedIds as string[])]);
  };

  const handleItemsAction = async (cancelReason?: number) => {
    try {
      let cancelItems: string[] = [];
      let cancelErrorItems: string[] = [];
      let splitPurchaseId = '';
      let splitPurchaseNumber = '';

      switch (itemsAction) {
        case EOrderItemsAction.Cancel:
          ({ errors: cancelErrorItems, success: cancelItems } = await purchaseOrdersService.cancelOrderItems(
            poId,
            selectedItems,
            cancelReason as number
          ));
          break;

        case EOrderItemsAction.Remove:
          await purchaseOrdersService.removeOrderItems(poId, selectedItems);
          break;

        case EOrderItemsAction.Split:
          ({ purchaseId: splitPurchaseId, purchaseNumber: splitPurchaseNumber } =
            await purchaseOrdersService.splitOrderItems(poId, selectedItems));
          break;

        default:
          return;
      }

      const newOrderItems = orderItems.reduce((result: IItemInfo[], orderItem) => {
        if (orderItem.itemId && selectedItems.includes(orderItem.itemId)) {
          if (itemsAction === EOrderItemsAction.Cancel) {
            if (orderItem.sku && cancelItems.includes(orderItem.sku)) {
              return [
                ...result,
                {
                  ...orderItem,
                  status: { id: EOrderItemStatus.Canceled, name: t('purchaseOrders:poItems.canceled') }
                }
              ];
            }
          } else {
            return result;
          }
        }

        return [...result, orderItem];
      }, []);

      setSelectedItems([]);
      setValue('items', newOrderItems);

      if (
        itemsAction !== EOrderItemsAction.Cancel ||
        (itemsAction === EOrderItemsAction.Cancel && cancelItems.length)
      ) {
        enqueueSnackbar(
          <Trans t={t} i18nKey={`purchaseOrders:poItems.${itemsAction}Success`}>
            {itemsAction === EOrderItemsAction.Split && (
              <a className={classes.notificationLink} href={`/purchase-order/${splitPurchaseId}`}>
                {{ splitPurchaseNumber }}
              </a>
            )}
          </Trans>,
          { autoHideDuration: itemsAction === EOrderItemsAction.Split ? 15000 : 5000, className: classes.notification }
        );
      }

      if (cancelErrorItems.length) {
        enqueueSnackbar(t(`purchaseOrders:poItems.cancelItemsError`, { itemList: cancelErrorItems.join(', ') }), {
          autoHideDuration: 20000,
          variant: 'error'
        });
      }
    } catch {
      enqueueSnackbar(t(`purchaseOrders:poItems.${itemsAction as EOrderItemsAction}Error`), {
        variant: 'error'
      });
    } finally {
      setItemsAction(undefined);
    }
  };

  const handleExportItems = useCallback(() => {
    const exportItems = orderItems.reduce(
      (result: Pick<IItemInfo, 'sellerCode' | 'sku'>[], { itemId, sellerCode, sku }) =>
        !selectedItems.length || (selectedItems.length && selectedItems.includes(itemId as string))
          ? [
              ...result,
              {
                sku,
                // eslint-disable-next-line sort-keys-fix/sort-keys-fix
                sellerCode
              }
            ]
          : result,
      []
    );

    // Generate worksheet and workbook.
    const worksheet = XLSX.utils.json_to_sheet(exportItems);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, t('purchaseOrders:orderItems'));

    // Set headers.
    XLSX.utils.sheet_add_aoa(worksheet, [[t('common:sku'), t('common:sellerCode')]], { origin: 'A1' });

    // Set columns width.
    worksheet['!cols'] = Array.from({ length: 2 }, () => ({ wch: 20 }));

    // Create an XLSX file and download.
    XLSX.writeFile(workbook, `${poNumber}_${t('purchaseOrders:items')}.xlsx`);
  }, [poNumber, orderItems, selectedItems]);

  const headerButtons: IHeaderButton[] = [
    {
      disabled: !!poId && status !== EPurchaseOrderAllStatus.Draft,
      icon: <MaterialIcons.CloudUpload />,
      label: t('purchaseOrders:importItems'),
      onClick: () => setOpenImportModal(true)
    },
    {
      disabled: !poId,
      icon: <MaterialIcons.GetApp />,
      onClick: handleExportItems,
      tooltip: t('purchaseOrders:poItems.export')
    },
    {
      disabled:
        !selectedItems.length ||
        initialOrderItems.length === selectedItems.length ||
        status === EPurchaseOrderAllStatus.Cancelled ||
        status === EPurchaseOrderAllStatus.Finalized,
      icon: <MaterialIcons.CallSplit />,
      onClick: () => setItemsAction(EOrderItemsAction.Split),
      tooltip: t('purchaseOrders:poItems.split')
    },
    {
      disabled:
        !selectedItems.length ||
        status === EPurchaseOrderAllStatus.Cancelled ||
        status === EPurchaseOrderAllStatus.Review,
      icon: <MaterialIcons.Cancel />,
      onClick: () => setItemsAction(EOrderItemsAction.Cancel),
      tooltip: t('common:cancel')
    },
    {
      disabled: !selectedItems.length || status !== EPurchaseOrderAllStatus.Draft,
      icon: <MaterialIcons.Delete />,
      onClick: () => setItemsAction(EOrderItemsAction.Remove),
      tooltip: t('purchaseOrders:poItems.remove')
    },
    {
      disabled: !selectedItems.length,
      label: t('purchaseOrders:poItems.assignTrackingNumber'),
      onClick: () => setOpenTrackingNumberModal(true)
    }
  ];

  const getActiveFilters = (filter: EOrderItemsFilterId, id: string) =>
    activeFilters[filter].length ? activeFilters[filter].includes(id) : true;

  useEffect(() => {
    const itemsFiltered = orderItems.filter(
      ({
        brand: { id: brandId },
        category: { id: categoryId },
        isFinalized,
        sellerCode,
        sku,
        status: { id: statusId },
        trackingNumbers
      }) => {
        const brandFilter = getActiveFilters(EOrderItemsFilterId.Brand, brandId);
        const categoryFilter = getActiveFilters(EOrderItemsFilterId.Category, categoryId);
        const statusFilter = getActiveFilters(EOrderItemsFilterId.Status, statusId);
        const templatedFilter = getActiveFilters(EOrderItemsFilterId.Templated, isFinalized ? 'true' : 'false');
        const trackingNumberFilter = getActiveFilters(
          EOrderItemsFilterId.TrackingNumber,
          trackingNumbers?.length ? 'true' : 'false'
        );

        return (
          (sellerCode?.toLowerCase().includes(searchText) || sku?.toLowerCase().includes(searchText)) &&
          brandFilter &&
          categoryFilter &&
          statusFilter &&
          templatedFilter &&
          trackingNumberFilter
        );
      }
    );

    setfilteredItems(itemsFiltered);
  }, [activeFilters, orderItems, searchText]);

  const formatErrorWarnings = (result: string, { message, rowNumber }: IItemErrorWarning) =>
    result + ` - ${t('purchaseOrders:poItems.row')} ${rowNumber}: ${message}\n`;

  const handleFileSubmit = async (file: File) => {
    try {
      setOpenImportModal(false);
      setIsLoading(true);

      const {
        data: items,
        errors,
        warnings
      } = await purchaseOrdersService.importOrderItems(supplierId, businessModelId, file);

      if (errors.length) {
        const errorMessage = errors.reduce(formatErrorWarnings, '');

        // These are raw as there are no designs on this yet and we should take time to implement this properly on the provider.
        enqueueSnackbar(errorMessage, {
          autoHideDuration: 20000,
          style: { maxHeight: '200px', maxWidth: '600px', overflow: 'auto', whiteSpace: 'pre-line' },
          variant: 'error'
        });
      }

      if (warnings.length) {
        const warningMessage = warnings.reduce(formatErrorWarnings, '');

        enqueueSnackbar(warningMessage, {
          autoHideDuration: 20000,
          style: { maxHeight: '200px', maxWidth: '600px', overflow: 'auto', whiteSpace: 'pre-line' },
          variant: 'warning'
        });
      }

      setValue('items', [...items, ...initialOrderItems], { shouldDirty: true });
    } catch {
      enqueueSnackbar(t('purchaseOrders:poItems.importError'), { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddTrackingNumber = (trackingNumbers: ITrackingNumber[]) => {
    setOpenTrackingNumberModal(false);

    const newOrderItems = orderItems.map(item => {
      if (item.itemId && selectedItems.includes(item.itemId)) {
        return {
          ...item,
          trackingNumbers:
            selectedItems.length === 1
              ? trackingNumbers
              : [...(item.trackingNumbers || []), ...trackingNumbers].filter(
                  ({ code, courier: { id } }, index, array) =>
                    array.findIndex(
                      trackingNumber => trackingNumber.code === code && trackingNumber.courier.id === id
                    ) === index
                )
        };
      }

      return item;
    });

    setValue('items', newOrderItems, { shouldDirty: true });
    enqueueSnackbar(t('purchaseOrders:poItems.trackingNumberAssignSuccess'), {
      className: classes.notification
    });
  };

  return (
    <>
      <Controller
        control={control}
        name="items"
        render={() => (
          <LuxDataTables
            checkboxSelection
            currentColumnFilters={activeFilters as unknown as TColumnFiltersValues}
            dataGridRows={filteredItems}
            dataGridColumns={columns}
            dataGridGetRowId={row => row.itemId || row.sellerCode}
            dataGridPageOptions={[10, 25, 50]}
            dataGridPageSize={pageSize}
            filterColumns={filters}
            headerActionsDisabled={!orderItems.length}
            headerButtons={headerButtons}
            headerTitle={`${poNumber ? `${poNumber} ` : ''}${t('purchaseOrders:items')} (${orderItems.length})`}
            isLoading={isLoading}
            isRowSelectable={({ row }) => row.itemId}
            onChangePageSize={newPageSize => setPageSize(newPageSize)}
            onChangeTextSearch={value => setSearchText(value)}
            onSelectionChange={handleSelectedItems}
            rowHeight={96}
            selectedRows={selectedItems.filter(id => filteredItems.some(({ itemId }) => id === itemId))}
            textSearchPlaceholder={t('purchaseOrders:poItems.searchPlaceholder')}
          />
        )}
      />
      {openImportModal && <ImportItemsModal onClose={() => setOpenImportModal(false)} onSubmit={handleFileSubmit} />}
      {itemsAction && (
        <ActionsModal action={itemsAction} onClose={() => setItemsAction(undefined)} onSubmit={handleItemsAction} />
      )}
      {openTrackingNumberModal && (
        <TrackingNumberModal
          couriers={couriers}
          isLoadingCouriers={isLoadingCouriers}
          onClose={() => setOpenTrackingNumberModal(false)}
          onSubmit={handleAddTrackingNumber}
          selectedItems={selectedItems}
        />
      )}
    </>
  );
};

export default OrderItems;
