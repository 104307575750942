import { css, styled } from '@lux-ds/theming/dist/cjs/styles';
import { createStyles, makeStyles, Theme } from '@luxclusif/material';

const orderItemsStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    capitalizeCell: {
      textTransform: 'capitalize'
    },
    photoCell: {
      lineHeight: 'normal !important'
    },
    notification: {
      whiteSpace: 'pre-wrap'
    },
    notificationLink: {
      color: palette.common.white,
      cursor: 'pointer'
    },
    skuHyperlink: {
      '& a:hover, a:link, a:visited ': {
        color: '#1B181B',
        '&:hover': {
          color: '#6A626A'
        }
      }
    }
  })
);

export default orderItemsStyles;

export const TextStyle = styled('div')(
  () => css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `
);
